import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "../blockHeading/heading";
import "../../../assets/scss/plugins/_slick.scss"
import Slider from "react-slick";
import './blockDeepInto.scss';
import StyledButton from "../../common/styledButton"
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useState } from "react"
import HTMLReactParser from "html-react-parser"

const BlkDeepInto = ({ content }) => {

    const [sliderNav, setSliderNav] = useState(null)
    const [sliderMain, setsliderMain] = useState(null)

    const settingsNav = {
        dots: false,
        infinite: true,
        speed: 500,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 801,
                settings: {
                    variableWidth: true
                },
            },
            {
                breakpoint: 640,
                settings: {
                    variableWidth: true
                },
            },
        ],
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className="blk-deep-into">
            <Container>
                <Row>
                    <Col md={9}>
                        <Heading
                            caption={content.block_deep_into_mru_slider_capt}
                            title={content.block_deep_into_mru_slider_title}
                            description={content.block_deep_into_mru_slider_desc.processed}
                        />
                    </Col>
                    <Col md={3}>
                        {content.block_deep_into_mru_slider_link &&
                            <div>
                                <StyledButton
                                    title={content.block_deep_into_mru_slider_link.title}
                                    path={content.block_deep_into_mru_slider_link.url}
                                    type="link"
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Slider {...settingsNav} asNavFor={sliderMain} ref={slider => (setSliderNav(slider))} className="sliderNav">
                            {content.relationships.block_deep_into_mru_slider_items.length > 0 && content.relationships.block_deep_into_mru_slider_items.map((item, index) => {
                                return (
                                  <div key={index}>
                                      {item.deep_into_mru_slider_item_title}
                                  </div>
                                )
                            })}
                        </Slider>
                        <Slider {...settings} asNavFor={sliderNav} ref={slider => (setsliderMain(slider))}>
                            {content.relationships.block_deep_into_mru_slider_items.length > 0 && content.relationships.block_deep_into_mru_slider_items.map((item, index) => {
                                return (
                                  <div key={index} className="itemSlider">
                                      <div>
                                          <div className="elementHeading">
                                              <span className="caption animTxt">{item.deep_into_mru_slider_item_capt}</span>
                                              <h3 className="font-style_2 animTxt animTxt_2"><span>{item.deep_into_mru_slider_item_title}</span></h3>
                                              <div className="description">
                                                  {HTMLReactParser(item.deep_into_mru_slider_item_desc.processed)}
                                              </div>
                                          </div>
                                          {
                                            item.deep_into_mru_slider_item_link &&
                                            <StyledButton
                                              title={item.deep_into_mru_slider_item_link.title}
                                              path={item.deep_into_mru_slider_item_link.url}
                                              type="link"
                                            />
                                          }
                                      </div>
                                      {
                                        item.relationships.deep_into_mru_slider_item_medias.length > 0 &&
                                        item.relationships.deep_into_mru_slider_item_medias.map((media, index) => {
                                            return (
                                              <figure className="sliderImg" key={index}>
                                                  <GatsbyImage image={getImage(media.relationships.field_image.gatsbyImage)} alt={media.field_image.alt} />
                                              </figure>
                                            )
                                        })
                                      }
                                  </div>
                                )
                            })}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}


export const query = graphql`
  fragment deepInto on paragraph__block_deep_into_mauritius_slider {
    block_deep_into_mru_slider_capt
    block_deep_into_mru_slider_desc {
      processed
    }
    block_deep_into_mru_slider_link {
      title
      url
    }
    block_deep_into_mru_slider_title
    relationships {
      block_deep_into_mru_slider_items {
        deep_into_mru_slider_item_capt
        deep_into_mru_slider_item_title
        deep_into_mru_slider_item_link {
          title
          url
        }
        deep_into_mru_slider_item_desc {
          processed
        }
        relationships {
          deep_into_mru_slider_item_medias {
            field_image {
                alt
            }
            relationships {
                ...getImage
            }
          }
        }
      }
    }
  }
`

export default BlkDeepInto
