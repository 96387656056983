import { useIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { getPostLink, formatPostDate } from "../../../services/post.service"
import { Link } from "gatsby"
import Heading from "../heading"
import Title from "../title"

const BlogItem = ({ post }) => {
  const intl = useIntl()

  return (
    <div>
      <div className="blk-article elem-clickable">
        <div className="animImgRight">
          <Link aria-label={post.title} to={getPostLink(post.link)}>
          {post.featuredImage &&
            post.featuredImage?.node &&
            post.featuredImage?.node?.gatsbyImage && (
              <GatsbyImage
                image={getImage(
                  post.featuredImage.node.gatsbyImage
                )}
                alt={post.featuredImage.node.altText}
                height="auto"
              />
            )}
          </Link>
        </div>
        <div className="blk-article-infos">
          <span className="caption">
            {
              post.categories.nodes.length > 0 &&
              post.categories.nodes.map((category, index) => {
                if (index > 0) {
                  return (
                    <span key={category.name}>
                      {" • " + category.name}
                    </span>
                  )
                } else {
                  return <span key={category.name}>{category.name}</span>
                }
              })
            }
          </span>
          <Title heading={3} size={2} text={post.title} />
          <Link className={"link-hidden"} to={getPostLink(post.link)}>
            {intl.formatMessage({ id: "show_more" })}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BlogItem
