import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import PageIntro from "../components/common/pageIntro/pageIntro"
import ChoixHotels from "../components/common/choixHotels/choixHotels"
import BlogItems from "../components/common/blogItems/blogItems"
import IllusExp from "../components/common/blockIllus/blockIllus"
import BlockFeeds from "../components/common/blockFeeds/blockFeeds"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import BlockBooking from "../components/common/blockBooking/blockBooking"
import Blkmarquee from "../components/common/marquee/marquee"
import BlkExpSig from "../components/common/blockExpSig/blkExpSig"
import BlkMeaSlider from "../components/common/blockMeaSlider/blockMeaSlider"
import BlkPushFeat from "../components/common/blockPushFeat/blockPushFeat"
import { useIntl } from "gatsby-plugin-react-intl"
import { debounce, getCarouselImageOG } from "../components/functions"
import { setSeoData } from "../services/seo.service"
import BlockConfigExperience from "../components/common/blockConfigExperiance/blockConfigExperience"
import BlkDeepInto from "../components/common/blockDeepInto/blockDeepInto"

const IndexPage = ({ data: { nodeHomepage, allTaxonomyTermGenre, allWpPost }, pageContext }) => {

  const isSSR = typeof window === "undefined"

  const {
    title,
    field_seo,
    relationships: {
      homepage_carousel: carousel,
      homepage_introduction: intro,
      homepage_transition_image_1: mediaSeparator1,
      homepage_block_resorts_villas: hotels,
      homepage_marquee_1: marquee1,
      homepage_tiled_exp_block_1: titledExperiences1,
      homepage_marquee_2: marquee2,
      homepage_deep_into_mru_slider: deepInto,
      homepage_feat_content_block_1: featuredContent,
    },
  } = nodeHomepage

  const intl = useIntl()

  const carouselMea = carousel
    ? carousel.relationships.carousel_highlight_item
    : null

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  let locale
  locale = intl.locale

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="home">
      <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
          <MainBannerImage items={carouselFiltered} isHomepage={1} />
      )}
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {intro && <PageIntro slice={intro} />}
          {marquee1 && <Blkmarquee content={marquee1} />}
          <ChoixHotels content={hotels} />
          {titledExperiences1 && <BlkExpSig content={titledExperiences1} />}
          {mediaSeparator1 && (
            <IllusExp content={mediaSeparator1} blockID="illusSep1" />
          )}
          {deepInto && <BlkDeepInto content={deepInto} />}
          <BlockConfigExperience locale={pageContext.locale} genres={allTaxonomyTermGenre.nodes}/>  
          {marquee2 && <Blkmarquee content={marquee2} />}
          {featuredContent && <BlkPushFeat content={featuredContent} />}
          {allWpPost.nodes && allWpPost.nodes.length > 0 && (
            <BlogItems posts={allWpPost.nodes} />
          )}
          <BlockFeeds locale={locale} />
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!, $languageWP: WpLanguageCodeEnum!) {
    nodeHomepage(title: { ne: "Dummy" }, langcode: { eq: $language }) {
      ...homepageFields
    }
    allWpPost(
        filter: { language: { code: { eq: $languageWP } } }, limit: 4
        sort: {order: DESC, fields: date}
      ) {
      nodes {
        ...mostRecentPostsFields
      }
    }
    allTaxonomyTermGenre (filter: { langcode: { eq: $language }}, sort: {fields: name, order: ASC}){
      nodes {
        name
        id
      }
    }
  }
`

export default IndexPage
