import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./blockIllus.scss"
import { graphql } from "gatsby"
import Media from "../media"
import TripAdvisor from "../tripAdvisor/tripAdvisor"
import { debounce } from "../../functions"
import { getSrc } from "gatsby-plugin-image"

const IllusExp = props => {
  let isFluid = 1

  if (typeof props.fullWidth !== "undefined") {
    isFluid = 0
  }

  const [hotelClassId, setHotelClassId] = useState()

  useEffect(() => {
    if (props.hotelId != "undefined" && props.hotelId != null) {
      setHotelClassId("hotelClassExist")
    } else {
      setHotelClassId("hotelClassFalse")
    }
  })

  const SeparatorImage = props => {
    const separatorImg =
      props.content && props.content.relationships.block_media_separator_media
        ? getSrc(
            props.content.relationships.block_media_separator_media
              .relationships.field_image.gatsbyImage
          )
        : null

    return (
      <section className="section-image-anim" id={hotelClassId}>
        <Container fluid={isFluid}>
          <Row>
            <Col xs={12}>
              <div className="sectionImage">
                <div
                  className="bg-zoom"
                  style={{ backgroundImage: "url(" + separatorImg + ")" }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  const SeparatorVideo = props => {
    const separatorImg =
      props.content && props.content.relationships.block_media_separator_media
        ? props.content.relationships.block_media_separator_media
        : null
    return (
      <section className="blk-spacing">
        <Container>
          <Row>
            <Col className="mediaWide">
              <Media media={separatorImg} />
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  return (
    <>
      {props.content?.relationships.block_media_separator_media?.internal
        .type === "media__image" && <SeparatorImage {...props} />}
      {props.content?.relationships.block_media_separator_media?.internal
        .type === "media__wistia" && <SeparatorVideo {...props} />}
    </>
  )
}

export const query = graphql`
  fragment getSeparatorMedia on paragraph__block_media_separator {
    internal {
      type
    }
    drupal_internal__id
    relationships {
      block_media_separator_media {
        ... on media__image {
          field_image {
            alt
          }
          internal {
            type
          }
          relationships {
            field_image {
              gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
        ... on media__wistia {
          internal {
            type
          }
          field_media_oembed_video
          relationships {
            thumbnail {
              gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  
  fragment getSeparatorMedia2 on paragraph__block_media_separator_2 {
    internal {
      type
    }
    drupal_internal__id
    relationships {
      block_media_separator_media {
        ... on media__image {
          field_image {
            alt
          }
          internal {
            type
          }
          relationships {
            field_image {
              gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
        ... on media__wistia {
          internal {
            type
          }
          field_media_oembed_video
          relationships {
            thumbnail {
              gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
      }
      category: block_media_separator_category {
        name
        drupal_id
      }
    }
  }
`
export default IllusExp
