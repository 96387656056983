import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "../../../assets/scss/plugins/_slick.scss"
import "./blogItems.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import BlogItem from "./blogItem"
import Heading from "../heading"
import styled from "styled-components"
import Sticky from "react-stickynode"
import {debounce} from "../../functions";
import {graphql, useStaticQuery} from "gatsby"
import HTMLReactParser from "html-react-parser"
import Slider from "react-slick"
import StyledButton from "../styledButton"
import { getBlogLink } from "../../../services/post.service"
import { getSrc } from "gatsby-plugin-image"


const BlogItems = ({ posts }) => {
  const intl = useIntl()
  const breakpoint = 801

  const [screen, setMobile] = useState({
    isMobile: typeof window !== `undefined` && window.innerWidth < breakpoint,
  })

  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)

    const debouncedHandleResize = debounce(function handleResize() {
      setMobile({
        isMobile:
          typeof window !== `undefined` && window.innerWidth < breakpoint,
      })
    }, 200)

    if (typeof window !== `undefined`) {
      window.addEventListener("resize", debouncedHandleResize)
    }
  }, [])

  const settings = {
    infinite: false,
    fade: false,
    centerMode: false,
    centerPadding: "20px",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2.5
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: true,
          slidesToShow: 1
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query blockOurJournalQuery {
      blkJournalEn: blockContentBlockNews(langcode: {eq: "en"}) {
        block_news_title
        block_news_caption
        block_news_description {
          processed
        }
        relationships {
          block_news_bg {
            relationships {
              field_image {
                gatsbyImage(breakpoints: [800], width: 800, formats: WEBP, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      blkJournalFr: blockContentBlockNews(langcode: {eq: "fr"}) {
        block_news_title
        block_news_caption
        block_news_description {
          processed
        }
        relationships {
          block_news_bg {
            relationships {
              field_image {
                gatsbyImage(breakpoints: [800], width: 800, formats: WEBP, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  // Get our journal data by locale
  let dataOurjournal
  switch (intl.locale) {
    case "fr":
      if (data.blkJournalFr) {
        dataOurjournal = data.blkJournalFr
      }
      break
    default:
      if (data.blkJournalEn) {
        dataOurjournal = data.blkJournalEn
      }
  }

  const bgdImg = getSrc(dataOurjournal.relationships.block_news_bg.relationships.field_image.gatsbyImage)
  // .images.fallback.src
  // Add right section styled component with background image
  const RightSection = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;

    * {
      color: #fff !important;
    }
    
     @media screen and (max-width: 801px) {
      height: auto;
     }

  `

  return (
    <section className="blk-blog-home">
      <Container fluid>
        <Row>
          <Col sm={6} className="col-right">
            {isClient && screen.isMobile ? (
                <RightSection style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(" + bgdImg + ")" }}>
                  <div className="blk-blog-home-infos">
                    <div>
                      <Heading
                        title={dataOurjournal.block_news_title}
                        caption={dataOurjournal.block_news_caption}
                        description={HTMLReactParser(dataOurjournal.block_news_description.processed)}
                      />
                      <StyledButton
                          title={intl.formatMessage({ id: "block_blog.cta" })}
                          type="link"
                          path={getBlogLink(intl.locale)}
                      />
                    </div>
                  </div>
                </RightSection>
            ) : (
                <Sticky enabled={true} top={0} bottomBoundary="#stickyEnd">
                  <RightSection style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(" + bgdImg + ")" }}>
                    <div className="blk-blog-home-infos">
                      <div>
                        <Heading
                          title={dataOurjournal.block_news_title}
                          caption={dataOurjournal.block_news_caption}
                          description={HTMLReactParser(dataOurjournal.block_news_description.processed)}
                        />
                        <StyledButton
                            title={intl.formatMessage({ id: "block_blog.cta" })}
                            type="link"
                            path={getBlogLink(intl.locale)}
                        />
                      </div>
                    </div>
                  </RightSection>
                </Sticky>
            )}
          </Col>
          <Col sm={6}>
            {isClient && screen.isMobile ? (
              <Slider {...settings} className="sliderBlogMob">
                {posts.map(post => {
                  return <BlogItem key={post.title} post={post} />
                })}
              </Slider>
            ) : (
              <>
                {posts.map(post => {
                  return <BlogItem key={post.title} post={post} />
                })}
              </>
            )}
            <div id="stickyEnd"></div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BlogItems
